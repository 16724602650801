.components {
    display: flex !important;
    flex-direction: column;
    justify-content: space-around;
}

.component {
    word-break: break-word;
}

.labHeader {
    margin: unset !important;
}

.tenantName {
    font-weight: bold;
    font-size: .85rem;
}

.toggleControl {
    font-size: 0.75rem !important;
}

.dropdownIcon {
    margin-left: 1rem !important;

}

.ui.accordion .title:not(.ui) {
    padding: none !important
}

.ui.accordion:not(.styled) .accordion .title~.content:not(.ui),
.ui.accordion:not(.styled) .title~.content:not(.ui) {
    padding: none !important
}

.labComponent {
    padding-top: 5px !important;
    padding-bottom: 5px !important;
}

.labSegment {
    margin: 0rem !important;
    padding: 0.57142857em !important;
}

.ui.grid{
    margin-bottom: 0px !important;
}