.docframe img {
    display: block;
    margin-top: 1em;
    margin-bottom: 1em;
    border: 1.5px solid #AFABA1;
    border-radius: 15px;
    margin-left: auto;
    margin-right: auto;
    max-width: 100%;
}

.docframe ::marker {
    font-weight: bolder;
}

.docframe blockquote {
    background-color: #dbe0fa;
    padding: 1em;
    border-radius: 15px;
}

.docframe blockquote h3 {
    font-size: 1.5rem;
}

.docframe pre {
    color: white;
    white-space: pre-wrap;
    background-color: rgb(40, 44, 52);
    border: 1.5px solid #AFABA1;
    border-radius: 15px;
    padding: 1em;
    margin-left: 40px;
    margin-right: 40px;
}

code {
    word-wrap: break-word;
    box-decoration-break: clone;
    padding: .1rem .3rem .2rem;
    border-radius: .2rem;
}

.codeWrapper {
    display: inline;
}

.codeStyle {
    padding: 0.4em !important;
    font-size: 12px !important;
    display:inline-table;
}

.ui.inverted.button {
    box-shadow: 0 0 0 2px rgb(171, 178, 191) inset !important;
    color: rgb(171, 178, 191);
}

img[alt~="contexttag"] {
    display: inline-block;
    margin: 0em auto;
    margin-top: -1em;
    height: 1.9em;
}