#tableofcontents {
    background-color: white;
    margin: 0rem;
    padding-top: 0rem !important;
    padding-bottom: 0rem !important;
}

.ui.list .list>.item a.header,
.ui.list>.item a.header {
    color: #272727 !important;
    font-family: inter, sans-serif !important;
    color: #343433 !important;
    font-weight: unset;
    font-size: medium;
}

.ui.list .list>.item a.header,
.ui.list>.item a.header:hover {
    color: #272727 !important;
    font-family: inter, sans-serif !important;
    filter: brightness(150%);
}

.ui.list .list>a.item {
    color: #343433 !important;
    filter: brightness(150%);
}

.ui.list .list>a.item:hover {
    filter: brightness(100%);
}

.activeStep > span > .MuiTypography-root {
    font-weight: bolder !important;
}

.activeStep > div > .MuiTypography-root {
    font-weight: bolder !important;
}

.sticky-container {
    position: sticky;
    top: 0;
    z-index: 1;
}

.contentList > .MuiListItem-root> .MuiListItemIcon-root{
    margin-right: 0.5em !important;
}

.contentList > .MuiListItem-padding{
    padding: .3em !important;
}

.subList > .MuiListItem-padding{
    padding: 0px !important;
}

.subList{
    padding-left: 3em !important;
}

.contentList > .MuiListItem-root:hover {
    cursor: pointer;
}

.subList > .MuiListItem-root:hover {
    cursor: pointer;
}