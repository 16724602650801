@font-face {
  font-family: aeonik;
  src: url('https://cdn.demo.okta.com/fonts/aeonik-regular.woff2') format('woff2');
}

body {
  min-height: 100%;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: transparent !important;
}

.App {
  display: flex !important;
  min-height: 100vh;
  flex-direction: column;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.appComponent {
  margin-top: 2.5em;
  margin-bottom: 2.5em;
}

html,
body {
  height: 100%;
}

body {
  color: #272727;
  font-family: 'Inter', sans-serif !important;
  font-weight: 400;
  font-size: 1rem;
}

b, strong {
  font-weight: 600;
}

.ContentArea {
  display: flex !important;
  flex: 1 0 auto;
  flex-direction: column;
}

h1, h2,h3, h4, h5 {
  font-family: 'Aeonik','Inter','-apple-system','BlinkMacSystemFont','Segoe UI','Oxygen-Sans','Ubuntu','Cantarell','Helvetica Neue',sans-serif;
  font-weight: 500;
  font-variant: normal;
}

h1 {
  font-size: 2.571rem;
}

h2 {
  font-size: 2.286rem;
}

h3 {
  font-size: 2rem;
}

h4 {
  font-size: 1.571rem;
}

h4 {
  font-size: 1.286rem;
}

a.brandColor{
  color:#546be7 !important;
}

.ctaButton {
  background-color: white !important;
  color: #191919 !important;
  padding-top: 1rem;
  padding-bottom: 1rem;
  padding-left: 3rem;
  padding-right: 3rem;
  border-width: 0.09375rem;
  border-radius: 6px;
  border-style: solid;
  transition: background-color .4s, border-color .4s;
  font-size: 1.25rem;
  line-height: 160%;
  margin-top: .75rem !important;
}

.ctaButton:hover {
  background-color: #1A31A9 !important;
  color: white !important;
}

.brandMessage {
  margin-bottom: 1.5rem !important;
  font-family: 'Aeonik-Regular', 'Helvetica Neue', sans-serif;
}

#innerDimmer {
  position: unset !important;
}

.secondaryCtaButton{
  font-size: 1.1rem !important;
  letter-spacing: 0.06em !important;
  border: transparent 1px solid !important;
  background-color: #546be7 !important;
  color: white !important;
  margin-top: 1rem !important;
  transition: background-color .4s,border-color .4s !important;
}
.secondaryCtaButton:hover {
  border: #191919 1px solid !important;
  background-color: white!important;
  color: #191919 !important;
}

.secondaryCtaButtonOutline:hover{

  border: transparent 1px solid !important;
  background-color: #191919 !important;
  color: white !important;
  margin-top: 1rem !important;
}
.secondaryCtaButtonOutline {
  transition: background-color .4s,border-color .4s !important;
  font-size: 1.1rem !important;
  letter-spacing: 0.06em !important;
  border: #191919 1px solid !important;
  background-color: white!important;
  color: #191919 !important;
}

.launchButton {
  margin-top: 1em !important;
  padding-right: 2.5em !important;
  padding-left: 2.5em !important;
  background-color: black !important;
  color: white !important;
  border: 1px solid black !important;
  margin-top: 5px !important;
  margin-bottom: 5px !important;
}

.credentialButton {
  margin-top: -15px !important;
}

.credentialText {
  font-size: .85rem;
}

.launchButton:hover {
  background-color: #1A31A9 !important;
  color: white !important;
}

.ui[class*="right labeled button launchButton"].icon.button {
  padding-right: 2.5em !important;
  padding-left: 1em !important;
}

.ui.labeled.icon.button>.icon,
.ui.labeled.icon.buttons>.button>.icon {
  background-color: transparent;
}

.branded {
  background-color: #191919 !important;
  color: white !important;
}

.branded:hover {
  background-color: #3f59e4 !important;
}

.contentHeader>.ui.sub.header {
  text-transform: none !important;
}

.refreshControl {
  display: inline !important;
  font-size: 0.4em !important;
  padding-left: 0.5em;
}

.refreshControl:hover {
  cursor: pointer !important;
  color: #3f59e4;
}

.clickable:hover {
  cursor: pointer !important;
}

.status {
  padding-left: 1em;
}

.promoCopy{
  font-size: larger;
  margin-bottom: 1rem;
}

.promoImage{
  max-width: 100%;
  border-radius: 1rem;
}
.promoImageContainer{
  padding-left: 0rem !important;
  padding-right: 0rem !important;
}

.columnLayout{
  display: flex !important;
  flex-direction: column !important;
}

.MuiCard-root {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.MuiCard-root img{
  height: 32px !important;
}

.MuiCardActions-root {
  margin-top: auto !important;
}

.css-glxda1 {
  margin-block-end: 1.1428571rem !important;
}
.hidden{
  display: none !important;
}

#staffingList li {
  padding-left: 0px !important;
}

#staffingList li {
  padding-right: 0px !important;
}
.MuiFormControlLabel-label div div{
  display: none;
}

.credential button {
  padding: 0px;
  height: 20px;
}